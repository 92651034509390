import api from "../_axios";
const getBooks = (
  search: string,
  subject_id: number | null,
  school_years: number[],
  education_types: number[],
  teacher_name: string
) => {
  const params = new URLSearchParams();
  if (search) {
    params.append("search", search);
  }
  if (subject_id !== null) {
    params.append("subject_id", subject_id.toString());
  }
  if (school_years.length > 0) {
    school_years.forEach((year) => {
      params.append("school_years[]", year.toString());
    });
  }
  if (education_types.length > 0) {
    education_types.forEach((type) => {
      params.append("education_types[]", type.toString());
    });
  }
  if (teacher_name) {
    params.append("teacher_name", teacher_name);
  }
  return api.get(`/books?${params.toString()}`);
};
const getSingleBook = (id: number) => api.get(`/books/${id}`);
const buyBook = (data: any) => api.post("/books", data);
const checkInvoice = (id: any) => api.get(`/check-invoice/${id}`);
// cart
const getCart = () => api.get("/cart");
const addToCart = (data: any) => api.post("/cart", data);
const updateCartItem = (id: number, data: any) =>
  api.post(`/update-cart-item-quantity/${id}`, data);
const deleteCartItem = (id: number) => api.delete(`/cart/${id}`);

const getTeachers = (search: string, school_years: number[]) => {
  const params = new URLSearchParams();
  if (search) {
    params.append("search", search);
  }
  if (school_years.length > 0) {
    school_years.forEach((year) => {
      params.append("school_years[]", year.toString());
    });
  }

  return api.get(`/teachers?${params.toString()}`);
};

const getSingleTeachers = (id: number) => api.get(`/teachers/${id}`);

const getExternalCodes = (search: string, school_years: number[]) => {
  const params = new URLSearchParams();

  if (search) {
    params.append("search", search);
  }

  if (school_years.length > 0) {
    school_years.forEach((year) => {
      params.append("school_years[]", year.toString());
    });
  }

  return api.get(`/external-codes?${params.toString()}`);
};
const getSingleExternalCode = (id: number) => api.get(`/external-codes/${id}`);
const buyExternalCodes = (data: any) => api.post("/external-codes", data);

const getProfile = () => api.get("/profile");
const updateProfile = (data: any) => api.post("/profile", data);
const changePassword = (data: any) =>
  api.post("/profile-change-password", data);

const getHomeDashboard = ({
  queryName,
  queryValue,
}: {
  queryName: string;
  queryValue: string;
}) => api.get(`/home-dashboard?${queryName}=${queryValue}`);

const getHome = () => api.get(`/home`);

const getInvoices = (page: number) =>
  api.get(`/invoices?${page ? `page=${page}` : ""}`);

const getWallet = ({
  queryName,
  queryValue,
}: {
  queryName: string;
  queryValue: string;
}) => api.get(`/wallet?${queryName}=${queryValue}`);
const chargeWallet = (data: any) => api.post("/charge-wallet", data);

const getSubjects = (search: string, school_years: number[]) => {
  const params = new URLSearchParams();
  if (search) {
    params.append("search", search);
  }
  if (school_years.length > 0) {
    school_years.forEach((year) => {
      params.append("school_years[]", year.toString());
    });
  }

  return api.get(`/subjects?${params.toString()}`);
};

const getMySubjects = () => api.get("/my-subjects");
const getMyCourses = () => api.get("/my-courses");
const getMyCourse = (id: string | number) => api.get(`/my-courses/${id}`);
const getMyExams = (id: string | number) => api.get(`/my-courses/${id}/exams`);

const getCourses = (search: string, school_years: number[], page: number) => {
  const params = new URLSearchParams();
  if (search) {
    params.append("search", search);
  }
  if (page) {
    params.append("page", page.toString());
  }
  if (school_years.length > 0) {
    school_years.forEach((year) => {
      params.append("school_years[]", year.toString());
    });
  }
  return api.get(`/courses?${params.toString()}`);
};

const buyCourse = (data: any, isFullExam?: any) =>
  api.post(`/${isFullExam ? "full-exams" : "courses"}`, data);
const subscribeWithTeacher = (data: any) => api.post("/teachers", data);

const getSingleCourse = (id: number, page: number, myComments: number) =>
  api.get(
    `/courses/${id}?${page ? `page=${page}&` : ""}${
      myComments ? `myComments=${myComments}&` : ""
    }`
  );

const getVideoConfiguration = (id: number, data: any) =>
  api.post(`/inkrypt-otp/${id}`, data);
const updateVideoStatus = (id: number, data: any) =>
  api.post(`/update-video-status/${id}`, data);
const updateLessonStatus = (id: number, data: any) =>
  api.post(`/update-lesson-status/${id}`, data);
const startExam = (data: any) => api.post("/start-exam", data);
const solveExam = (data: any) => api.post("/solve-exam", data);
const endExam = (data: any) => api.post("/end-exam", data);

// full exams
const getFullExams = (search: string, school_years: number[]) => {
  const params = new URLSearchParams();
  if (search) {
    params.append("search", search);
  }
  if (school_years.length > 0) {
    school_years.forEach((year) => {
      params.append("school_years[]", year.toString());
    });
  }
  return api.get(`/full-exams?${params.toString()}`);
};

const getSingleFullExam = (id: number) => api.get(`/full-exams/${id}`);
const getMyFullExams = (id: string | number) => api.get(`/my-full-exams/${id}`);

const startFullExam = (data: any) => api.post("/start-full-exam", data);
const solveFullExam = (data: any) => api.post("/solve-full-exam", data);
const endFullExam = (data: any) => api.post("/end-full-exam", data);

// comments
const getCommentReplies = (commentId: number, page: number) =>
  api.get(`/comments/${commentId}/replies?page=${page ? page : 1}`);
const addComment = (data: any) => api.post("/comments", data);
const updateComment = (commentId: number, data: any) =>
  api.post(`/comments/${commentId}`, data);
const deleteComment = (commentId: number) =>
  api.delete(`/comments/${commentId}`);

const USERAPI = {
  getHomeDashboard,
  getBooks,
  buyBook,
  getCart,
  addToCart,
  updateCartItem,
  deleteCartItem,
  getTeachers,
  getSingleTeachers,
  getExternalCodes,
  buyExternalCodes,
  getProfile,
  updateProfile,
  changePassword,
  getInvoices,
  getWallet,
  chargeWallet,
  getSubjects,
  getMySubjects,
  getMyCourses,
  getMyCourse,
  getMyExams,
  getSingleBook,
  getSingleExternalCode,
  getCourses,
  getSingleCourse,
  buyCourse,
  subscribeWithTeacher,
  getVideoConfiguration,
  updateVideoStatus,
  updateLessonStatus,
  startExam,
  solveExam,
  endExam,
  // full exams
  getFullExams,
  getSingleFullExam,
  getMyFullExams,
  startFullExam,
  solveFullExam,
  endFullExam,
  getHome,
  // comments
  getCommentReplies,
  addComment,
  updateComment,
  deleteComment,
  checkInvoice,
};
export default USERAPI;
