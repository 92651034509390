import { createAsyncThunk } from "@reduxjs/toolkit";
import ADMINAPI from "./api";

const doGetProfile = createAsyncThunk<any, any, any>(
  "user/doGetProfile",
  async ({ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getProfile();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doUpdateProfile = createAsyncThunk<any, any, any>(
  "user/doUpdateProfile",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateProfile(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doChangePassword = createAsyncThunk<any, any, any>(
  "user/doChangePassword",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.changePassword(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// AdminLogin
const doAdminLogin = createAsyncThunk<any, any, any>(
  "admin/doAdminLogin",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.adminLogin(data);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Home
const doGetHome = createAsyncThunk<any, any, any>(
  "admin/doGetHome",
  async ({ queryName, queryValue }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getHome({
        queryName,
        queryValue,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Permissions
const doGetPermissions = createAsyncThunk<any, any, any>(
  "admin/doGetPermissions",
  async ({ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getPermissions();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetMyPermissions = createAsyncThunk<any, any, any>(
  "admin/doMyGetPermissions",
  async ({ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getMyPermissions();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Subjects
const doGetSubjects = createAsyncThunk<any, any, any>(
  "admin/doGetSubjects",
  async ({ _ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSubjects();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleSubject = createAsyncThunk<any, any, any>(
  "admin/doGetSingleSubject",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleSubject(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doCreateSubject = createAsyncThunk<any, any, any>(
  "admin/doCreateSubject",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createSubject(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateSubject = createAsyncThunk<any, any, any>(
  "admin/doUpdateSubject",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateSubject(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteSubject = createAsyncThunk<any, any, any>(
  "admin/doDeleteSubject",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteSubject(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Teachers
const doGetTeachers = createAsyncThunk<any, any, any>(
  "admin/doGetTeachers",
  async ({ _ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getTeachers();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleTeacher = createAsyncThunk<any, any, any>(
  "admin/doGetSingleTeacher",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleTeacher(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doCreateTeacher = createAsyncThunk<any, any, any>(
  "admin/doCreateTeacher",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createTeacher(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateTeacher = createAsyncThunk<any, any, any>(
  "admin/doUpdateTeacher",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateTeacher(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteTeacher = createAsyncThunk<any, any, any>(
  "admin/doDeleteTeacher",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteTeacher(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateTeachersOrder = createAsyncThunk<any, any, any>(
  "admin/doUpdateTeachersOrder",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateTeachersOrder(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Books
const doGetBooks = createAsyncThunk<any, any, any>(
  "admin/doGetBooks",
  async ({ _ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getBooks();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleBook = createAsyncThunk<any, any, any>(
  "admin/doGetSingleBook",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleBook(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doCreateBook = createAsyncThunk<any, any, any>(
  "admin/doCreateBook",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createBook(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateBook = createAsyncThunk<any, any, any>(
  "admin/doUpdateBook",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateBook(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteBook = createAsyncThunk<any, any, any>(
  "admin/doDeleteBook",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteBook(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ExternalCodes
const doGetExternalCodes = createAsyncThunk<any, any, any>(
  "admin/doGetExternalCodes",
  async ({ _ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getExternalCodes();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetExternalCode = createAsyncThunk<any, any, any>(
  "admin/doGetExternalCode",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getExternalCode(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doCreateExternalCode = createAsyncThunk<any, any, any>(
  "admin/doCreateExternalCode",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createExternalCode(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateExternalCode = createAsyncThunk<any, any, any>(
  "admin/doUpdateExternalCode",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateExternalCode(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteExternalCode = createAsyncThunk<any, any, any>(
  "admin/doDeleteExternalCode",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteExternalCode(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// InternalCodes
const doGetInternalCodes = createAsyncThunk<any, any, any>(
  "admin/doGetInternalCodes",
  async ({ _ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getInternalCodes();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetInternalCode = createAsyncThunk<any, any, any>(
  "admin/doGetInternalCode",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getInternalCode(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doCreateInternalCode = createAsyncThunk<any, any, any>(
  "admin/doCreateInternalCode",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createInternalCode(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateInternalCode = createAsyncThunk<any, any, any>(
  "admin/doUpdateInternalCode",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateInternalCode(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteInternalCode = createAsyncThunk<any, any, any>(
  "admin/doDeleteInternalCode",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteInternalCode(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Moderators
const doGetModerators = createAsyncThunk<any, any, any>(
  "admin/doGetModerators",
  async ({ _ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getModerators();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleModerator = createAsyncThunk<any, any, any>(
  "admin/doGetSingleModerator",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleModerator(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doCreateModerator = createAsyncThunk<any, any, any>(
  "admin/doCreateModerator",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createModerator(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateModerator = createAsyncThunk<any, any, any>(
  "admin/doUpdateModerator",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateModerator(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteModerator = createAsyncThunk<any, any, any>(
  "admin/doDeleteModerator",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteModerator(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetStudents = createAsyncThunk<any, any, any>(
  "admin/doGetStudents",
  async ({ page, params }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getStudents(page, params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleStudent = createAsyncThunk<any, any, any>(
  "admin/doGetSingleStudent",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleStudent(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetStudentExams = createAsyncThunk<any, any, any>(
  "admin/doGetStudentExams",
  async ({ studentId, courseId, isFullExam }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getStudentExams(
        studentId,
        courseId,
        isFullExam
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doCorrectQuestion = createAsyncThunk<any, any, any>(
  "admin/doCorrectQuestion",
  async ({ id, data, isFullExam }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.correctQuestion(id, data, isFullExam);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Users
const doGetUsers = createAsyncThunk<any, any, any>(
  "admin/doGetUsers",
  async ({ page, params }, { rejectWithValue }) => {
    try {
      console.log(params, "GET students from admin");

      const response = await ADMINAPI.getUsers(page, params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetSingleuser = createAsyncThunk<any, any, any>(
  "admin/doGetSingleuser",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleUser(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doCreateUser = createAsyncThunk<any, any, any>(
  "admin/doCreateUser",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createUser(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateUserStatus = createAsyncThunk<any, any, any>(
  "admin/doUpdateUserStatus",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateUserStatus(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateUser = createAsyncThunk<any, any, any>(
  "admin/doUpdateUser",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateUser(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteUser = createAsyncThunk<any, any, any>(
  "admin/doDeleteUser",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteUser(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// courses and videos
const doGetAllVideos = createAsyncThunk<any, any, any>(
  "admin/doGetAllVideos",
  async ({ teacherId, page, limit }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getAllVideos(teacherId, page, limit);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetTeacherVideos = createAsyncThunk<any, any, any>(
  "admin/doGetTeacherVideos",
  async ({ teacherId }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getTeacherVideos(teacherId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetCourses = createAsyncThunk<any, any, any>(
  "admin/doGetCourses",
  async ({ _ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getCourses();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleVideo = createAsyncThunk<any, any, any>(
  "admin/doGetSingleVideo",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleVideo(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleCourse = createAsyncThunk<any, any, any>(
  "admin/doGetSingleCourse",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleCourse(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doCreateVideo = createAsyncThunk<any, any, any>(
  "admin/doCreateVideo",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createVideo(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doCreateCourse = createAsyncThunk<any, any, any>(
  "admin/doCreateCourse",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createCourse(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateVideo = createAsyncThunk<any, any, any>(
  "admin/doUpdateVideo",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateVideo(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteVideo = createAsyncThunk<any, any, any>(
  "admin/doDeleteVideo",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteVideo(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateCourse = createAsyncThunk<any, any, any>(
  "admin/doUpdateCourse",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateCourse(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteCourse = createAsyncThunk<any, any, any>(
  "admin/doDeleteCourse",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteCourse(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// courses - chapters
const doCreateCourseChapter = createAsyncThunk<any, any, any>(
  "admin/doCreateCourseChapter",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createCourseChapter(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateCourseChapter = createAsyncThunk<any, any, any>(
  "admin/doUpdateCourseChapter",
  async ({ courseId, chapterId, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateCourseChapter(
        courseId,
        chapterId,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteCourseChapter = createAsyncThunk<any, any, any>(
  "admin/doDeleteCourseChapter",
  async ({ courseId, chapterId }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteCourseChapter(courseId, chapterId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// courses - chapters - lessons
const doCreateCourseLesson = createAsyncThunk<any, any, any>(
  "admin/doCreateCourseLesson",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createCourseLesson(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteCourseLesson = createAsyncThunk<any, any, any>(
  "admin/doDeleteCourseLesson",
  async ({ chapterId, lessonId }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteCourseLesson(chapterId, lessonId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// full exam
const doGetFullExams = createAsyncThunk<any, any, any>(
  "admin/doGetFullExams",
  async ({ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getFullExams();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetSingleFullExam = createAsyncThunk<any, any, any>(
  "admin/doGetSingleFullExam",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleFullExam(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doCreateFullExam = createAsyncThunk<any, any, any>(
  "admin/doCreateFullExam",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createFullExam(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateFullExam = createAsyncThunk<any, any, any>(
  "admin/doUpdateFullExam",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateFullExam(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doDeleteFullExam = createAsyncThunk<any, any, any>(
  "admin/doDeleteFullExam",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteFullExam(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doCreateFullExamQuestion = createAsyncThunk<any, any, any>(
  "admin/doCreateFullExamQuestion",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createFullExamQuestion(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Money

const doGetMoneyRequests = createAsyncThunk<any, any, any>(
  "admin/doGetMoneyRequests",
  async ({ _ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getMoneyRequests();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doUpdateLesson = createAsyncThunk<any, any, any>(
  "admin/doUpdateLesson",
  async ({ chapterId, lessonId, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateLesson(chapterId, lessonId, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doUpdateLessonsOrder = createAsyncThunk<any, any, any>(
  "admin/doUpdateLessonsOrder",
  async ({ chapterId, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateLessonsOrder(chapterId, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetTeacherChapters = createAsyncThunk<any, any, any>(
  "admin/doGetTeacherChapters",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getTeacherChapters(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doDuplicateChapter = createAsyncThunk<any, any, any>(
  "admin/doDuplicateChapter",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.duplicateChapter(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetMoneyRequest = createAsyncThunk<any, any, any>(
  "admin/doGetMoneyRequest",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getMoneyRequest(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doCreateMoneyRequest = createAsyncThunk<any, any, any>(
  "admin/doCreateMoneyRequest ",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.createMoneyRequest(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateMoneyRequest = createAsyncThunk<any, any, any>(
  "admin/doUpdateMoneyRequest",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateMoneyRequest(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doDeleteFullExamQuestion = createAsyncThunk<any, any, any>(
  "admin/doDeleteFullExamQuestion",
  async ({ id, questionId }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteFullExamQuestion(id, questionId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateFullExamQuestionsOrder = createAsyncThunk<any, any, any>(
  "admin/doUpdateFullExamQuestionsOrder",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateFullExamQuestionsOrder(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Finances

const doGetFinances = createAsyncThunk<any, any, any>(
  "admin/doGetFinances",
  async ({ _ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getFinances();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetSingleBookSalesInfo = createAsyncThunk<any, any, any>(
  "admin/doGetSingleBookSalesInfo",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleBookSalesInfo(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleTeacherFinanceInfo = createAsyncThunk<any, any, any>(
  "admin/doGetSingleTeacherFinanceInfo",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleTeacherFinanceInfo(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleUserChargeWalletInfo = createAsyncThunk<any, any, any>(
  "admin/doGetSingleUserChargeWalletInfo",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleUserChargeWalletInfo(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetSingleCourseFinanceInfo = createAsyncThunk<any, any, any>(
  "admin/doGetSingleCourseFinanceInfo",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleCourseFinanceInfo(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetSingleFullExamFinanceInfo = createAsyncThunk<any, any, any>(
  "admin/doGetSingleFullExamFinanceInfo",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleFullExamFinanceInfo(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetWallet = createAsyncThunk<any, any, any>(
  "admin/doGetWallet",
  async ({ queryName, queryValue }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getWallet({
        queryName,
        queryValue,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetSingleFullExamQuestion = createAsyncThunk<any, any, any>(
  "admin/doGetSingleFullExamQuestion",
  async ({ id, idQuestion }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getSingleFullExamQuestion(id, idQuestion);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doUpdateFullExamQuestion = createAsyncThunk<any, any, any>(
  "admin/doUpdateFullExamQuestion",
  async ({ id, idQuestion, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateFullExamQuestion(
        id,
        idQuestion,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetOrders = createAsyncThunk<any, any, any>(
  "admin/doGetOrders",
  async ({ page }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getOrders(page);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doGetOrdersForExport = createAsyncThunk<any, any, any>(
  "admin/doGetOrdersForExport",
  async ({ }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getOrdersForExport();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetComments = createAsyncThunk<any, any, any>(
  "admin/doGetComments",
  async ({ courseId, page, limit, replied, includeDeleted, review }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getComments(courseId, page, limit, replied, includeDeleted, review);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doAddComment = createAsyncThunk<any, any, any>(
  "admin/doAddComment",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.addComment(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doUpdateComment = createAsyncThunk<any, any, any>(
  "admin/doUpdateComment",
  async ({ commentId, data }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.updateComment(commentId, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const doDeleteComment = createAsyncThunk<any, any, any>(
  "admin/doDeleteComment",
  async ({ commentId }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.deleteComment(commentId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetCourseUsers = createAsyncThunk<any, any, any>(
  "admin/doGetCourseUsers",
  async ({ courseId, page, search }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getCourseUsers(courseId, page, search);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetCourseLessonsAnalytics = createAsyncThunk<any, any, any>(
  "admin/doGetCourseLessonsAnalytics",
  async ({ courseId }, { rejectWithValue }) => {
    try {
      const response = await ADMINAPI.getCourseLessonsAnalytics(courseId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const doGetCourseLessonsStudentsAnalytics = createAsyncThunk<any, any, any>(
  "admin/doGetCourseLessonsStudentsAnalytics",
  async (
    { courseId, lessonId, currentPage, searchTerm },
    { rejectWithValue }
  ) => {
    try {
      const response = await ADMINAPI.getCourseLessonsStudentsAnalytics(
        courseId,
        lessonId,
        currentPage,
        searchTerm
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const thunks = {
  doGetProfile,
  doUpdateProfile,
  doChangePassword,
  doGetPermissions,
  doGetMyPermissions,
  doGetHome,
  doGetSubjects,
  doGetSingleSubject,
  doCreateSubject,
  doUpdateSubject,
  doDeleteSubject,
  doGetTeachers,
  doGetSingleTeacher,
  doCreateTeacher,
  doUpdateTeacher,
  doDeleteTeacher,
  doUpdateTeachersOrder,
  doGetBooks,
  doGetSingleBook,
  doCreateBook,
  doUpdateBook,
  doDeleteBook,
  doGetExternalCodes,
  doGetExternalCode,
  doCreateExternalCode,
  doUpdateExternalCode,
  doDeleteExternalCode,
  doGetInternalCodes,
  doGetInternalCode,
  doCreateInternalCode,
  doUpdateInternalCode,
  doDeleteInternalCode,
  doGetModerators,
  doGetSingleModerator,
  doCreateModerator,
  doUpdateModerator,
  doDeleteModerator,
  doGetStudents,
  doGetSingleStudent,
  doGetStudentExams,
  doCorrectQuestion,
  doGetUsers,
  doGetSingleuser,
  doCreateUser,
  doUpdateUser,
  doUpdateUserStatus,
  doDeleteUser,
  doAdminLogin,
  doGetAllVideos,
  doGetTeacherVideos,
  doGetSingleVideo,
  doCreateVideo,
  doUpdateVideo,
  doDeleteVideo,
  // courses
  doGetCourses,
  doGetSingleCourse,
  doCreateCourse,
  doUpdateCourse,
  doDeleteCourse,
  doCreateCourseChapter,
  doUpdateCourseChapter,
  doDeleteCourseChapter,
  doCreateCourseLesson,
  doDeleteCourseLesson,
  doUpdateLesson,
  doUpdateLessonsOrder,
  doGetTeacherChapters,
  doDuplicateChapter,
  // Money
  doGetMoneyRequests,
  doGetMoneyRequest,
  doCreateMoneyRequest,
  doUpdateMoneyRequest,
  // Finances
  doGetFinances,
  doGetSingleBookSalesInfo,
  doGetSingleTeacherFinanceInfo,
  doGetSingleUserChargeWalletInfo,
  doGetSingleCourseFinanceInfo,
  doGetSingleFullExamFinanceInfo,
  // full exam
  doGetFullExams,
  doGetSingleFullExam,
  doCreateFullExam,
  doUpdateFullExam,
  doDeleteFullExam,
  doCreateFullExamQuestion,
  doDeleteFullExamQuestion,
  doUpdateFullExamQuestion,
  doGetSingleFullExamQuestion,
  doUpdateFullExamQuestionsOrder,

  // Home month chart
  doGetWallet,
  doGetOrders,
  doGetOrdersForExport,

  // comments
  doGetComments,
  doAddComment,
  doUpdateComment,
  doDeleteComment,
  doGetCourseUsers,
  doGetCourseLessonsAnalytics,
  doGetCourseLessonsStudentsAnalytics,
};

export default thunks;
